import style from  './Style.module.css';
import codium from '../../images/codium.png';
import React from 'react';
import Openednews from '../openednews/index';
import useFetch from '../../hooks/useFetch';

function News() {
  const test = [ 
    {
      image: codium,
      title: "test tes tetst etste ts",
      date: "test tes tetst etste ts",
      smalldesc: "test tes tetst etste ts",
    },  
    {
      image: codium,
      title: "test tes tetst etste ts2",
      date: "test tes tetst etste ts2",
      smalldesc: "test tes tetst etste ts2",
    },  
  ];

  const [openednews, setopenednews] = React.useState(false);
  const [opendata, setopendata] = React.useState([]);
  const [language, setlanguage] = React.useState("geo");

  React.useEffect(() => {
    const userLanguage = window.localStorage.getItem("USER_LANGUAGE");
    if (userLanguage !== null) {
      setlanguage(userLanguage);
      console.log(userLanguage);
    }
  }, []);

  const host = "https://api.gudauribusiness.com";
  const { loading, error, data } = useFetch(`${host}/api/mountaincart-newses?populate=*`);

  if (loading) return <div className={style.fixd}></div>;
  if (error) return <p></p>;

  console.log(data);

  function OpenBigNews(i) {
    setopenednews(true);
    setopendata(i);
  }

  const handleValueChange = () => {
    setopenednews(false);
  };

  return (
    <div className={style.main}>
      {openednews === false ? (
        <div className={style.main_cont}>
          {data.data.map((i, index) => (
            <div className={style.news} key={index}>
              <img className={style.news_image} src={`${host}${i.attributes.CoverImage.data.attributes.url}`} />
              <div className={style.rightside}>
                <div className={style.rightside_title}>{i.attributes.Title}</div>
                <div className={style.rightside_date}>{i.attributes.publishedAt.substring(0, 10)}</div>
                <div className={style.rightside_desc}>{i.attributes.SmallDescription}</div>
                <div className={style.news_button} onClick={() => OpenBigNews(i)}>
                  {language === "geo" ? "ვრცლად ნახვა" : "MORE DETAILS"}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Openednews props={opendata} triggerParentFunction={handleValueChange} />
      )}
    </div>
  );
}

export default News;