// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
  font:-apple-system-body;
}
@supports (font:-apple-system-body) and (not(-webkit-touch-callout:default)){
    /* Prevents Desktop Safari from setting size to 13px when -apple-system-body is set. Manually set to default 16px, as there is no way a user can set the Safari desktop font size default anyways. Which is a shame honestly. */
  /* Oh, by the way, that @supports line is a brand new CSS hack I invented for this to target Desktop Safari specifically */
    :root{font-size:16px}
}


/* --------------- END OF SCALING CODE --------------- */
body {
  margin: 0;
  font-size: 16px; /* Base font size */
  position: relative;
}
a {
  text-decoration: none !important; /* no underline */
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;AACA;IACI,+NAA+N;EACjO,0HAA0H;IACxH,MAAM,cAAc;AACxB;;;AAGA,wDAAwD;AACxD;EACE,SAAS;EACT,eAAe,EAAE,mBAAmB;EACpC,kBAAkB;AACpB;AACA;EACE,gCAAgC,EAAE,iBAAiB;AACrD","sourcesContent":[":root{\n  font:-apple-system-body;\n}\n@supports (font:-apple-system-body) and (not(-webkit-touch-callout:default)){\n    /* Prevents Desktop Safari from setting size to 13px when -apple-system-body is set. Manually set to default 16px, as there is no way a user can set the Safari desktop font size default anyways. Which is a shame honestly. */\n  /* Oh, by the way, that @supports line is a brand new CSS hack I invented for this to target Desktop Safari specifically */\n    :root{font-size:16px}\n}\n\n\n/* --------------- END OF SCALING CODE --------------- */\nbody {\n  margin: 0;\n  font-size: 16px; /* Base font size */\n  position: relative;\n}\na {\n  text-decoration: none !important; /* no underline */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
