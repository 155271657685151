import style from './Style.module.css';
import React from 'react';

const Mainpage = ({ triggerParentFunction }) => {
  const [language, setLanguage] = React.useState("geo");

  const sendValueToParent = () => {
    const valueToSend = 'contact';
    triggerParentFunction(valueToSend);
  };

  React.useEffect(() => {
    const userLanguage = window.localStorage.getItem("USER_LANGUAGE");
    if (userLanguage !== null) {
      setLanguage(userLanguage);
      console.log(userLanguage);
    }
  }, [window.localStorage.getItem("USER_LANGUAGE")]);

  return (
    <div>
      {/* First Page */}
      <div className={style.firstpage}>
        <div className={style.firstpage_container}>
          <div className={style.firstpage_leftside}>
            <div className={style.firstpage_leftside_title}>MOUNTAINCART GEORGIA</div>
            <div className={style.firstpage_leftside_description}>
              {language === "geo"
                ? "ტობოგანი და ციგით სრიალი ყოველთვის პოპულარობით სარგებლობდა მთის კურორტებზე, თუმცა ამ გასართობი საშუალებებით სარგებლობა მხოლოდ ზამთარში ან საკმარისი თოვლის არსებობისას არის შესაძლებელი. Mountaincart, რომელიც მხოლოდ 10 წელია რაც შეიქმნა, სახალისო სპორტული მოწყობილობაა, რომლის გამოყენებაც მთელი წლის განმავლობაშია შესაძლებელი."
                : "Tobaggoning and sled riding have always been popular, but is only possible during the winter and with enough snow. The mountaincart, which was developed more than 10 years ago, is a fun sport device you can enjoy a unique driving experience with all year round. It is completely produced in company which is in the upper Bavarian town Bad Aibling, near the highway junction between Munich, Salzburg and Innsbruck."}
            </div>
            <div className={style.firstpage_leftside_button_cont}>
              <div onClick={sendValueToParent} className={style.firstpage_leftside_button}>
                {language === "geo" ? "დაჯავშნე" : "ORDER NOW"}
              </div>
              <div className={style.firstpage_leftside_play_button}>
                <div className={style.firstpage_leftside_play_button_icon}></div>
              </div>
              <div className={style.firstpage_leftside_button_desc}>
                {language === "geo" ? "ვიდეოს ყურება" : "WATCH VIDEO"}
              </div>
            </div>
          </div>
          <div className={style.firstpage_rightside}></div>
        </div>

        <div className={style.firstpage_descriptions}>
          <div className={style.firstpage_descriptions_left}>
            <div className={style.firstpage_descriptions_left_desc}></div>
            <div className={style.firstpage_descriptions_left_title}>
              {language === "geo" ? "ჩვენ შესახებ" : "ABOUT US"}
            </div>
            <div className={style.firstpage_descriptions_left_desc1}>
              {language === "geo"
                ? "Cart-ები სრულად მზადდება ჩვენი პარტნიორი კომპანიის მიერ ზემო ბავარიაში მდებარე ქალაქ ბად აიბლინგში, მიუნხენთან, ზალცბურგის და ინსბრუკის საავტომობილო გზის კვანძთან ახლოს."
                : "The mountaincart can be found in more than 20 countries world-wide. Over 1 million rides with our mountaincart speak for its success. More and more families are enjoying the mountaincart"}
            </div>
            <div className={style.firstpage_descriptions_left_desc2}>
              {language === "geo"
                ? "Mountaincart უკვე გამოიყენება მსოფლიოს 20-ზე მეტ ქვეყანაში. Mountaincart-ით ჩატარებული მილიონზე მეტი დაშვება, მის წარმატებას ადასტურებს. უფრო და უფრო მეტი ოჯახი იღებს სიამოვნებას Mountaincart-ით."
                : "Therefore families with kids, the elderly and of course the young active sports lovers as well as those who participate in World and European Championships in racing are among our fans."}
            </div>
          </div>
          <div className={style.firstpage_descriptions_right}>
            {language === "geo"
              ? "სიამაყით წარმოგიდგენთ პირველ Mountaincart -ს საქართველოს ყველაზე პოპულარულ კურორტ გუდაურში. მისი ქირაობა შესაძლებელია skigudauri.ge -ს გასაქირავებელ პუნქტებში, გონდოლას საბაგიროს მიმდებარე ტერიტორიაზე."
              : "We are delighted to present this opportunity in Gudauri, Georgia’s most renowned ski resort."}
          </div>
        </div>
      </div>

      {/* Second Page */}
      <div className={style.secondpage}>
        <div className={style.secondpage_container}>
          <div className={style.secondpage_left}>
            <div className={style.secondpage_left_title_cont}>
              <div className={style.secondpage_left_title_cont_title}>
                {language === "geo" ? "უსაფრთხოება" : "SAFETY"}
              </div>
              <div className={style.secondpage_left_title_cont_desc}>
                {language === "geo"
                  ? "მომხმარებლების უსაფრთხოებას ყველაზე დიდი მნიშვნელობა ენიჭება Mountaincart-ის წარმოებაში, რომელიც შემდეგ მახასიათებლებს მოიცავს:"
                  : "SAFETY OF OUR CUSTOMERS HAS TOP PRIORITY IN THE PRODUCTION OF OUR MOUNTAINCART WHICH INCLUDES THE FOLLOWING FEATURES:"}
              </div>
            </div>
            <div className={style.secondpage_left_boxes}>
              <div className={`${style.secondpage_left_boxes_item} ${style.box_color}`}>
                <div className={style.secondpage_left_boxes_container}>
                  <div className={`${style.secondpage_left_boxes_item_title} ${style.colorer}`}>01</div>
                  <div className={`${style.secondpage_left_boxes_item_desc} ${style.colorer}`}>
                    {language === "geo"
                      ? "დაბალი გრავიტაციის ცენტრი და ფართო საბურავების ბაზა უზრუნველყოფს სიმაღლიდან დაშვების სტაბილურობას."
                      : "a low center of gravity and a wide wheel base for high driving stability"}
                  </div>
                </div>
              </div>
              <div className={style.secondpage_left_boxes_item}>
                <div className={style.secondpage_left_boxes_container}>
                  <div className={style.secondpage_left_boxes_item_title}>02</div>
                  <div className={style.secondpage_left_boxes_item_desc}>
                    {language === "geo"
                      ? "უნიკალური ჰიდრავლიკური დისკის მუხრუჭები (ორმაგი მუხრუჭების სისტემა) დამატებითი უსაფრთხოებისთვისაა გრძელ და მკვეთრად დახრილ გზებზე სასიარულოდ."
                      : "unique hydraulic disc brakes (dual-circuit braking system) for extra safety when going down long steep routes"}
                  </div>
                </div>
              </div>
            </div>
            <div className={style.secondpage_left_boxes}>
              <div className={style.secondpage_left_boxes_item}>
                <div className={style.secondpage_left_boxes_container}>
                  <div className={style.secondpage_left_boxes_item_title}>03</div>
                  <div className={style.secondpage_left_boxes_item_desc}>
                    {language === "geo"
                      ? "ერგონომიული დიზაინის სკამი დაშვებისას სხეულის კომფორტულ მდგომარეობას უზრუნველყოფს.- მაღალი ხარისხის ალუმინის კონსტრუქცია მარტივად მართვის და მთაზე აყვანის საშუალებას იძლევა."
                      : "an ergonomically designed seat ensures a relaxed ride"}
                  </div>
                </div>
              </div>
              <div className={style.secondpage_left_boxes_item}>
                <div className={style.secondpage_left_boxes_container}>
                  <div className={style.secondpage_left_boxes_item_title}>04</div>
                  <div className={style.secondpage_left_boxes_item_desc}>
                    {language === "geo"
                      ? "ამიტომ, Mountaincart -ის გულშემატკივრები არიან: ბავშვიანი ოჯახები, ხანდაზმულები და, რა თქმა უნდა, ახალგაზრდები, რომლებიც სპორტით აქტიურად არიან დაკავებულები."
                      : "high quality aluminum construction of the mountaincart allows for easy handling and makes it easier to pull the cart up the mountain."}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.secondpage_right}></div>
        </div>
      </div>

      {/* Third Page */}
      <div className={style.thirdpage}>
        <div className={style.thirdpage_container}>
          <div className={style.thirdpage_left}>
            <div className={style.thirdpage_left_title}>
              {language === "geo"
                ? "შეიგრძენით უნიკალური სიამოვნება და სიხარული Mountaincart-ით დაშვებისას!"
                : "Experience the unique thrill of a ride with the mountaincart:"}
            </div>
            <div className={style.thirdpage_left_desc}>
              {language === "geo"
                ? "გადაწყვიტეთ თავად, გსურთ თუ არა მთაზე ფეხით ან საბაგიროთი ასვლა, ან დაჯავშნეთ ორგანიზებული ტური Mountaincart-ის ღონისძიების ოპერატორთან!"
                : "decide for yourself whether you want to walk up the hill or use the ropeway or book an organized tour with a mountaincart event operator."}
            </div>
            <div onClick={sendValueToParent} className={style.firstpage_leftside_button}>
              {language === "geo" ? "დაჯავშნე" : "ORDER NOW"}
            </div>
          </div>
          <div className={style.thirdpage_right}></div>
        </div>
      </div>

      {/* Fourth Page */}
      <div className={style.fourthpage}>
        <div className={style.fourthpage_line}>
          <div className={style.fourthpage_line_item}></div>
          <div className={style.fourthpage_line_item2}></div>
        </div>
        <div className={style.fourthpage_line}>
          <div className={style.fourthpage_line_item3}></div>
          <div className={style.fourthpage_line_item4}></div>
          <div className={style.fourthpage_line_item5}></div>
        </div>
      </div>
    </div>
  );
};

export default Mainpage;