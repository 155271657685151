import style from  './Style.module.css';
import codium from '../../images/codium.png'
import React from 'react';

function Openednews({ props, triggerParentFunction }) { // Destructure props and triggerParentFunction

  const host = "https://api.gudauribusiness.com";
  // const host = "http://localhost:1337";

  const sendValueToParent = () => {
    if (typeof triggerParentFunction === 'function') {
      triggerParentFunction();
    }
  };

  const [paragraphs, setparagraphs] = React.useState([]);

  function ConvertDesc() {
    const sentences = props.attributes.BigDescription.split('.').filter(sentence => sentence.trim() !== '');
    const localparagraphs = [];
    for (let i = 0; i < sentences.length; i += 3) {
      const paragraph = sentences.slice(i, i + 3).join('. ') + (i + 3 < sentences.length ? '.' : '');
      localparagraphs.push(paragraph);
    }
    setparagraphs(localparagraphs);
  }

  React.useEffect(() => {
    ConvertDesc();
  }, [props.attributes.BigDescription]);

  return (
    <div className={style.main}>
      <div className={style.main_cont}>
        <div className={style.news}>
          <img className={style.news_image} src={`${host}${props.attributes.CoverImage.data.attributes.url}`} />
          <div className={style.rightside}>
            <div className={style.rightside_date}>
              {"Date: " + props.attributes.publishedAt.substring(0, 10)}
              <a onClick={sendValueToParent} className={style.news_button}>
                <div className={style.contfix}>
                  მთავარ გვერდზე <br />
                  <p className={style.fix}>დაბრუნება</p>
                </div>
              </a>
            </div>
            <div className={style.rightside_title}>{props.attributes.Title}</div>
            <div className={style.rightside_desc}>
              {paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph.trim()}</p>
              ))}
            </div>
            <div className={style.rightside_gallery}>
              <div>{props.attributes.images.data.map((i, index) => (
                <img key={index} className={style.rightside_gallery_item} src={`${host}${i.attributes.url}`} />
              ))}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Openednews;