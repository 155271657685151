
import style from  './Style.module.css';
import React from 'react';

function Contact() {

  const [language, setlanguage] = React.useState("geo")

  React.useEffect(()=> {
    if (window.localStorage.getItem("USER_LANGUAGE") !== null) {
       setlanguage(window.localStorage.getItem("USER_LANGUAGE"))   
       console.log(window.localStorage.getItem("USER_LANGUAGE"))
    } 
  }, [window.localStorage.getItem("USER_LANGUAGE")])

  return (

    <div>
    <div className={style.firstpage}>

    <div className={style.firstpage_container}>
    <div className={style.firstpage_leftside}>
    <div className={style.firstpage_leftside_title}>{language === "geo" ? "MOUNTAINCART ის ქირაობა  შეგიძლიათ" : "You can rent Mountaincart"} </div>  
    <div className={style.firstpage_leftside_description}>{language === "geo" ? "გონდოლის საბაგიროს წინ" : "front of Gondola lift"}</div>  
    <div className={style.firstpage_leftside_description2}><div className={style.firstpage_leftside_desc_call}></div>+995 514 500 200</div>  
    <div className={style.firstpage_leftside_button_cont}>
    <div className={style.firstpage_leftside_desc_title}>{language === "geo" ? "გაქვთ კითხვები?" : "ANY QUETIONS?"}</div>   
    <div className={style.firstpage_leftside_desc}>{language === "geo" ? "დაუკავშირდით Mountaincart-ის ღონისძიებების ოპერატორს  ზემოთ მოცემულ ნომერზე." : "You can contact Mountaincart event operator"}</div>   
    </div>  
    </div>  
    <div className={style.firstpage_rightside}></div>

    </div>
    <div className={style.firstpage_descriptions}>
    <div className={style.firstpage_descriptions_left}>
    <div className={style.firstpage_descriptions_left_desc}></div>
    <div className={style.firstpage_descriptions_left_title}></div>
    <div className={style.firstpage_descriptions_left_desc1}></div>
    <div className={style.firstpage_descriptions_left_desc2}></div>
    </div>
    <div className={style.firstpage_descriptions_right}></div>
    </div>
    </div>
    <div className={style.secondpage}>
    <div className={style.secondpage_container}>
    <div className={style.secondpage_left}>
    <div className={style.secondpage_left_title_cont}>
    <div className={style.secondpage_left_title_cont_title}>{language === "geo" ? "უსაფრთხოება" : "SAFETY"}</div>
    <div className={style.secondpage_left_title_cont_desc}>{language === "geo" ? "მომხმარებლების უსაფრთხოებას ყველაზე დიდი მნიშვნელობა ენიჭება Mountaincart-ის წარმოებაში, რომელიც შემდეგ მახასიათებლებს მოიცავს:" : "SAFETY OF OUR CUSTOMERS HAS TOP PRIORITY IN THE PRODUCTION OF OUR MOUNTAINCART WHICH INCLUDES THE FOLLOWING FEATURES:"}</div>
    </div>
    <div className={style.secondpage_left_boxes}>
    <div className={`${style.secondpage_left_boxes_item} ${style.box_color}`}>
    <div className={style.secondpage_left_boxes_container}>
    <div className={`${style.secondpage_left_boxes_item_title} ${style.colorer}`}>01</div>
    <div className={`${style.secondpage_left_boxes_item_desc} ${style.colorer}`}>{language === "geo" ? "დაბალი გრავიტაციის ცენტრი  და ფართო საბურავების ბაზა უზრუნველყოფს სიმაღლიდან დაშვების სტაბილურობას." : "a low center of gravity and a wide wheel base for high driving stability"}</div>
    </div>
    </div>
    <div className={style.secondpage_left_boxes_item}>
    <div className={style.secondpage_left_boxes_container}>
    <div className={style.secondpage_left_boxes_item_title}>02</div>
    <div className={style.secondpage_left_boxes_item_desc}>{language === "geo" ? "უნიკალური ჰიდრავლიკური დისკის მუხრუჭები (ორმაგი მუხრუჭების სისტემა) დამატებითი უსაფრთხოებისთვისაა გრძელ და მკვეთრად დახრილ გზებზე სასიარულოდ." : "unique hydraulic disc brakes (dual-circuit braking system) for extra safety when going down long steep routes"}</div>
    </div>
    </div>
    </div>
    <div className={style.secondpage_left_boxes}>
    <div className={style.secondpage_left_boxes_item}>
    <div className={style.secondpage_left_boxes_container}>
    <div className={style.secondpage_left_boxes_item_title}>03</div>
    <div className={style.secondpage_left_boxes_item_desc}>{language === "geo" ? "ერგონომიული დიზაინის სკამი დაშვებისას სხეულის კომფორტულ მდგომარეობას უზრუნველყოფს.- მაღალი ხარისხის ალუმინის კონსტრუქცია მარტივად მართვის და მთაზე აყვანის საშუალებას იძლევა." : "an ergonomically designed seat ensures a relaxed ride"}</div>
    </div>
    </div>
    <div className={style.secondpage_left_boxes_item}>
    <div className={style.secondpage_left_boxes_container}>
    <div className={style.secondpage_left_boxes_item_title}>04</div>
    <div className={style.secondpage_left_boxes_item_desc}>{language === "geo" ? "ამიტომ, Mountaincart -ის გულშემატკივრები არიან: ბავშვიანი ოჯახები, ხანდაზმულები და, რა თქმა უნდა, ახალგაზრდები, რომლებიც სპორტით აქტიურად არიან დაკავებულები." : "high quality aluminum construction of the mountaincart allows for easy handling and makes it easier to pull the cart up the mountain."}</div>
    </div>
    </div>
    </div>
    </div>
    <div className={style.secondpage_right}></div>
    </div>
    </div>
    <div className={style.fourthpage}>
    <div className={style.fourthpage_line}>
    <div className={style.fourthpage_line_item}></div>
    <div className={style.fourthpage_line_item2}></div>
    </div>
    <div className={style.fourthpage_line}>
    <div className={style.fourthpage_line_item3}></div>
    <div className={style.fourthpage_line_item4}></div>
    <div className={style.fourthpage_line_item5}></div>
    </div>
    </div>

    </div>
    
  );
}

export default Contact;
