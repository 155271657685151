//      ######                                                                                                                               
//      ##########                                                                                                                             
//      ###  #####  #                  ##########        ###########       ##############        ####   ####       #####   #######        ######      
//      ### ####   #####             ##############    ###############     ################      ####   ####       #####   ########      #######      
//          #####    #####         ######      ###    ######      #####    #####       #####     ####   ####       #####   ########     ########      
//   ######    ######   ####      ######             #####         #####   #####         ####    ####   ####       #####   #########   #########      
//   ########    ####### ###      #####              #####          ####   #####         #####   ####   ####       #####   ########## ##### ####      
//   ###   ######    ### ###      #####              #####          ####   #####         #####   ####   ####       #####   ##### ########## ####      
//   ### #   ######  ##  ###      ######             ######        #####   #####         ####    ####   ####       #####   #####  ########  ####      
//    ######  #####   ######        ######    ####    #######   #######    #####      ######     ####    #####    #####    #####  #######   ####      
//       ### ####    ####            ##############     #############      ################      ####    #############     #####   #####    ####      
//       ### #  ###                     ########          #########        #############         ####       #########      #####            ####      
//       ##########                                                                                                                            
//        ######   

import style from './App.module.css';
import React from 'react';
import Mainpage from './components/mainpage/index';
import Contact from './components/contact/index';
import News from './components/news/index';

function App() {
  const [activepage, setActivePage] = React.useState("mainpage");
  const [language, setLanguage] = React.useState("geo");

  React.useEffect(() => {
    const userLanguage = window.localStorage.getItem("USER_LANGUAGE");
    if (userLanguage !== null) {
      setLanguage(userLanguage);
    }
  }, []);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    window.localStorage.setItem("USER_LANGUAGE", lang);
  };

  const handleValueChange = (value) => {
    setActivePage(value);
  };

  return (
    <div className={style.main}>
      <div className={style.header}>
        <div className={style.header_cont}>
          <div className={style.navbar}>
            <div className={style.navbar_decoration}></div>
            <div onClick={() => setActivePage("mainpage")} className={style.navbar_logo}></div>
            <div onClick={() => setActivePage("mainpage")} className={style.navitem}>
              {language === "geo" ? "მთავარი გვერდი" : "HOME"}
            </div>
            <div onClick={() => setActivePage("news")} className={style.navitem}>
              {language === "geo" ? "სიახლეები" : "NEWS"}
            </div>
            <div onClick={() => setActivePage("contact")} className={style.navitem}>
              {language === "geo" ? "გაქირავების სერვისი" : "RENTAL"}
            </div>
            <div onClick={() => setActivePage("contact")} className={style.navitem}>
              {language === "geo" ? "კონტაქტი" : "CONTACT"}
            </div>
            <div className={style.socials}>
              <div className={style.languages}>
                <div onClick={() => changeLanguage("geo")} className={style.languages_item}>
                  {language === "geo" ? "ქარ" : "GEO"}
                </div>
                <div className={style.languages_divider}>/</div>
                <div onClick={() => changeLanguage("eng")} className={style.languages_item}>
                  {language === "geo" ? "ინგ" : "ENG"}
                </div>
              </div>
              <a href="https://www.facebook.com/mountaincartgeo" target="_blank" className={style.socials_fb}></a>
              <a href="https://www.instagram.com/mountaincart_ge" target="_blank" className={style.socials_insta}></a>
            </div>
          </div>
        </div>
      </div>

      {activepage === "mainpage" ? <Mainpage triggerParentFunction={handleValueChange} /> :
        activepage === "contact" ? <Contact /> :
        activepage === "news" ? <News /> : null}

      <div className={style.footer}>
        <div className={style.footer_container}>
          <div className={style.footer_container_leftside}>
            {language === "geo" ? "© 2024 ყველა უფლება დაცულია. დამზადებულია " : "© 2024 All Rights Reserved / Powered By "}
            <a href="https://codium.ge/" target='_blank' className={style.codium}>Codium.ge</a>
          </div>
          <div className={style.footer_container_rightside}>
            <div onClick={() => setActivePage("mainpage")} className={style.footer_container_rightside_item}>
              {language === "geo" ? "მთავარი გვერდი" : "HOME"}
            </div>
            <div className={style.footer_container_rightside_dec}></div>
            <div onClick={() => setActivePage("news")} className={style.footer_container_rightside_item}>
              {language === "geo" ? "სიახლეები" : "NEWS"}
            </div>
            <div className={style.footer_container_rightside_dec}></div>
            <div onClick={() => setActivePage("contact")} className={style.footer_container_rightside_item}>
              {language === "geo" ? "გაქირავების სერვისი" : "RENTAL"}
            </div>
            <div className={style.footer_container_rightside_dec}></div>
            <div onClick={() => setActivePage("contact")} className={style.footer_container_rightside_item}>
              {language === "geo" ? "კონტაქტი" : "CONTACT"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;